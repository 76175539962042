import React from 'react'
import Helmet from 'react-helmet'
import { Player, ControlBar } from 'video-react'

import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'

import eighthWallLogo from '../assets/images/8th-wall-logo.png'
import nodeJsLogo from '../assets/images/nodejs-logo.png'
import aframeLogo from '../assets/images/aframe-logo.png'
import t3SxswPoster from '../assets/images/t3sxsw-poster.png'
import t3sxsw from '../assets/images/t3sxsw-green.png'

import t3SxswVideo from '../assets/videos/t3sxsw-custom-section.mp4'

const Landing = props => (
  <Layout>
    <Helmet
      title="Tyler Kemme | T3 SXSW"
      meta={[
        {
          name: 'description',
          content: 'T3 SXSW AR Application',
        },
        {
          name: 'keywords',
          content: 't3, AR, 8th wall, sxsw, web-based',
        },
      ]}
    >
      <html lang="en" />
    </Helmet>

    <BannerLanding
      title="T3 SXSW"
      description="Web-based AR Application for SXSW 2019"
      to="https://www.t-3.com/work/t3-labs"
      backgroundImage={t3sxsw}
    />

    <div id="main" className="landing-page">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h2>The Project</h2>
          </header>
          <p>
            As part of T3's 2019 SXSW Showcase, we created personalized posters
            for the concert attendees based on recordings of their heart rate
            and brain activity. We then leveraged 8th Wall's web-based AR
            platform to create a web application that could play a video of the
            event on top of the poster.
          </p>
        </div>
      </section>
      <section id="two" className="spotlights">
        <section>
          <div className="logo-soup three">
            <img
              src={eighthWallLogo}
              alt="8th wall logo"
              className="logo-soup-individual"
            />
            <img
              src={aframeLogo}
              alt="A-Frame logo"
              className="logo-soup-individual"
            />
            <img
              src={nodeJsLogo}
              alt="react logo"
              className="logo-soup-individual"
            />
          </div>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h2>Technologies Used</h2>
              </header>
              <p>
                This AR application was made using the following front-end
                technologies and served on a Node.js server
                <br />
                <br />
                <ul>
                  <li>
                    <a
                      href="https://www.8thwall.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      8th Wall
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://github.com/8thwall/web/tree/master/xrextras"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      XR Extras
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://aframe.io/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      A-Frame
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://nodejs.org/en/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Node.js
                    </a>
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </section>
        <section>
          <a
            href="https://www.youtube.com/watch?v=YKqjX8VkzYA&feature=youtu.be"
            className="image"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Player autoPlay muted loop>
              <source src={t3SxswVideo} />
              <ControlBar disableCompletely />
            </Player>
          </a>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>How I contributed</h3>
              </header>
              <p>
                I was responsible for the entire AR application including
                design, functionality, and serving the app on a Node.js server
                through AWS. I developed the application using 8th Wall's image
                tracking functionality and I used A-Frame to overlay the video
                onto the poster. Basic user interactions such as video controls
                were achieved using ES6. Styling and design was done with pure
                CSS and HTML.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="image">
            <img src={t3SxswPoster} alt="T3 South By Southwest Poster" />
          </div>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Additional Information</h3>
              </header>
              <p>
                For this application I used 8th Wall's{' '}
                <a
                  href="https://github.com/8thwall/web/tree/master/xrextras"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  XR Extras
                </a>{' '}
                library to implement loading screens, device detection, and
                camera permissions. Because 8th Wall web apps only work on the
                most modern devices and operating systems, we needed this
                library to detect if users were using the right phones and were
                on the most recent version of their OS. I forked the XR Extras
                library and created my own custom implementation so we could
                design loading and error screens to match the design of the rest
                of the application. XR extras was also extended to allow users
                to control the video with touch interactions and with an
                additional play/pause button.
              </p>
            </div>
          </div>
        </section>
      </section>
    </div>
  </Layout>
)

export default Landing
